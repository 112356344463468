import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { PackageService } from '../../../package/services/package.service';
import { CourseService } from '../../../course/services/course.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from '../../../shared/services/shared.service';
import { BalanceReportService } from '../../../balance-report/services/balance-report.service';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit {

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @Output() sendPackageCourse = new EventEmitter<Object>();
  @ViewChild('fileInput') fileInput: ElementRef;

  public form: FormGroup = new FormGroup({
    receiptNumber: new FormControl(''),
    rechargeAmount: new FormControl('')
  });

  selectedFiles: any = [];
  currentFileUpload: FileUpload;
  percentage: number = 0;

  public loading: boolean = false;

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopUpload: boolean = false;

  public isSubmit: any = false;
  public courses: any = null;
  public packages: any = null;

  public selectedRechargeAmount: any = null;
  public selectedReceiptNumber: any = null;

  public userDetail = <String>localStorage.getItem("userDetail");
  public requested_by = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;
  public currentUserRole: any = '';
  public maximumWalletAmount: number = 400;
  public validateMaximumWalletAmount: boolean = true;

  constructor(
    private uploadService: FileUploadService,
    private packageService: PackageService,
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private sharedService: SharedService,
    private balanceReportService: BalanceReportService
  ) {
    this.currentUserRole = this.requested_by.usertype_name;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      receiptNumber: ['', [Validators.required]],
      rechargeAmount: [null, [Validators.required]]
    });

    this.form.get('rechargeAmount')?.valueChanges.subscribe(value => {
      this.validateMaximumWalletAmount = true;
      this.getCurrentBalance();
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  selectFile(event: any): void {
    this.selectedFiles = Array.from(event.target.files);
    this.stopUpload = false;
    const validFiles = [];

    for (const file of this.selectedFiles) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (file.type.startsWith('image/') || fileExtension === 'doc' || fileExtension === 'docx' || fileExtension === 'pdf') {
        // Check if the file name has a file extension
        if (!(/\.[^/]+$/.test(file.name))) {
          alert(`File ${file.name} doesn't have a file extension.`);
          continue; // Skip this file
        }
        const currentDatetime = moment().utc().format('YYYYMMDDHHmmss');
        const renamedFile = new File([file], `${currentDatetime}_${file.name}`, { type: file.type });
        validFiles.push(renamedFile);
      } else {
        alert(`File ${file.name} is not an allowed type (image, .doc, .docx, .pdf).`);
      }
    }
    this.selectedFiles = validFiles;
  }

  async upload(event: any) {
    this.loading = true;

    if (this.form.valid) {
      event.stopPropagation();

      const response = await this.validateReceipt();

      if (!response) {
        this.loading = false;
        return;
      }

      this.isSubmit = true;
      this.selectedReceiptNumber = this.form.value.receiptNumber;
      this.selectedRechargeAmount = this.form.value.rechargeAmount;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles?.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileUpload = new FileUpload(file);
        let basePath = 'recharge-file';
        let subscribe = this.uploadService.pushFileToStorage(
          this.currentFileUpload,
          basePath,
          this.selectedReceiptNumber,
          this.selectedRechargeAmount
        ).subscribe(
          percentage => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = [];
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmit = false;
                this.loading = false;
                this.emitCourse();
              } else {
                this.currentIndex++;
                this.count++;
                this.upload(event);
                subscribe.unsubscribe();
              }
            }
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
      }
    } else {
      this.loading = false;
    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  emitCourse() {
    let selectedReceiptNumber = this.form.value.receiptNumber;
    let selectedRechargeAmount = this.form.value.rechargeAmount;

    this.sendPackageCourse.emit({
      receiptNumber: selectedReceiptNumber,
      rechargeAmount: selectedRechargeAmount
    });

    this.fileInput.nativeElement.value = "";
    this.form.reset();
  }

  validateReceipt() {

    let promise = new Promise((resolve, reject) => {
      let requestObject = {
        userId: this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ? this.requested_by.id : null,
        limit: 1,
        receiptNumber: this.form.value.receiptNumber
      };

      this.courseService.getAllRechargeFileOrById(requestObject).subscribe((response) => {
        const totalResult = response?.data?.countTotal;

        if (totalResult > 0) {
          this.sharedService.showSnackBar('You are trying to upload a duplicate receipt number');
          resolve(false);
        } else {
          resolve(true);
        }
      }, (error) => {
        this.sharedService.showSnackBar('Failed to validate receipt number. Please try again');
        resolve(false);
      });
    });

    return promise;
  }

  getCurrentBalance() {

    let balanceReportObject: any = {
      limit: 1,
      offset: 0,
      year: null,
      searchText: this.requested_by?.email
    };

    this.balanceReportService.getBalanceReport(balanceReportObject).subscribe(response => {

      const responseData = response?.data?.data ? response?.data?.data : [];

      const balance = responseData[0].balance;

      if((Number(balance) + Number(this.form?.value?.rechargeAmount)) >= Number(this.maximumWalletAmount)) {
        this.validateMaximumWalletAmount = true;
        this.sharedService.showSnackBar("Your wallet should not exceed RM " + this.maximumWalletAmount);
      } else {
        this.validateMaximumWalletAmount = false;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
}
